@media only screen and (max-width: 1200px) {
  .App {
    text-align: center;
    min-height: 100%;
  }

  #root {
    min-height: 100%;
    height: 100%;
  }

  .header-part {
    position: fixed;
    top: 0pt;
    width: 100%;
  }

  .content {
    padding: 50px 10px 10px 10px;
    height: 100%;
  }

  .titlebar {
    background-color: #333;
    color: #f2f2f2;
  }
  .navbar {
    overflow: hidden;
    top: 0px; /* Position the navbar at the top of the page */
    /*padding: 20px;*/
    width: 100%;
  }

  .navbar a {
    float: right;
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: #f2f2f2;
    text-align: center;
    padding: 5px 5px;
    margin-right: 5px;
    text-decoration: none;
  }

  .navbar a:hover {
  background: #0ed1f0;
  border-radius: 17px;
  }

  .page-link-active {
    background: #0ed1f0;
    border-radius: 17px;
  }

  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 12px;
    text-align: center;
    padding: 5px 16px;
  }

  .footer a {
    color: white;
    text-decoration: underline;
  }

  .pre-title {
    margin-top: 50px;
    font-size: 4vw;
    font-weight: bold;
    color: #f2f2f2;
  }

  .title {
    font-size: 8vw;
    font-weight: bold;
    color: #f2f2f2;
  }

  .text-title {
    margin-top: 20px;
    font-size: 3vw;
    font-weight: bold;
    color: #f2f2f2;
  }

  .about-box {
    display: grid;
    grid-template-columns: 100%;
    margin-bottom: 50px;
  }

  .about-column-text {
    padding: 5px;
    text-align: left;
  }

  .about-column-text h2 {
    font-size: 24px;
    font-weight: normal;
  }

  .about-column-text p {
    font-size: 14px;
  }

  .about-column-image {
    padding: 5px;
    align-content: center;
  }

  .center {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .concert {
    border: solid 1px #000;
    padding: 10px;
    margin-bottom: 20px;
  }

  .concert h3 {
    font-size: 16px;
    font-weight: bold;
  }

  .concert a {
    font-size: 14px;
    font-weight: normal;
    color: #f2f2f2;
    padding: 5px 20px;
    text-align: center;
    text-decoration: none;
    background: #000;
    border-radius: 17px;
  }

  .concert a:hover {
    background: #0ed1f0;
    border-radius: 17px;
  }

  .image-gallery {
    margin-top: 0px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    
    background-color: rgba(0, 0, 0, 0.8);
    padding: 30px 0px 30px 0px;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 150px) !important;
  }

  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    width: auto  !important;
  }

  .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    position: initial !important;
  }

  .text-link a {
    color:#000;
    text-decoration: underline;
  }
}